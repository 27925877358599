import { BrowserRouter } from "react-router-dom";
import BaseRouter from './components/Routes'



function App() {
  return (
    <BrowserRouter>
        <BaseRouter />
      </BrowserRouter>   
  );
}

export default App;
